<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <div>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            label="Masukan kata kunci ..."
            solo
            rounded
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>

          <div class="text-center mb-5" v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>

          <div
            v-else
            v-for="item in pengaduans"
            :key="item.pengaduan_id"
            class="mb-10"
          >
            <v-card class=" px-5 py-2">
              <h2 class="primary--text">{{ item.pengaduan_judul }}</h2>

              <div class="mt-2 mb-3">
                <v-row no-gutters>
                  <div class="mr-5">
                    <v-avatar color="primary" size="30">
                      <v-icon dark>
                        mdi-account-circle
                      </v-icon>
                    </v-avatar>
                    <span class="font-weight-medium ml-2 my-auto">{{
                      item.pengaduan_pengadu
                    }}</span>
                  </div>

                  <div class="text--secondary my-auto mr-5">
                    <v-icon small>mdi-tag-multiple-outline</v-icon>
                    <span class="text-caption ml-1 my-auto">{{
                      item.jp_nama
                    }}</span>
                  </div>

                  <div class="text--secondary my-auto">
                    <v-icon small>mdi-calendar</v-icon>
                    <span class="text-caption ml-1 my-auto">{{
                      tglFormat(item.pengaduan_created_at)
                    }}</span>
                  </div>
                </v-row>
              </div>

              <div>{{ item.pengaduan_isi.slice(0, 300) }}...</div>

              <v-divider class="my-5"></v-divider>

              <div class="text-right mb-5">
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="detail(item.pengaduan_slug)"
                >
                  Detail
                </v-btn>
              </div>
            </v-card>
          </div>

          <div class="mt-5" v-if="!loading">
            <v-pagination v-model="page" :length="totalRows"></v-pagination>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="4"
          style="position: sticky; top:70px; align-self: start;"
        >
          <v-card class="pa-5 mb-5">
            <v-btn class="error" block @click="pengaduanForm()">
              BUAT PENGADUAN
            </v-btn>
          </v-card>

          <v-card class="pa-5 mb-5">
            <h2 class="mb-2">KATEGORI</h2>

            <div v-for="item in JPs" :key="item.jp_id">
              <div>
                <span>{{ item.jp_nama }}</span>
              </div>
              <v-divider class="my-3"></v-divider>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import refreshView from "@/store/pengaduanForm/viewForm";

export default {
  name: "Pengaduan",

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
    },

    page() {
      this.getData();
      window.scrollTo(0, 0);
    },
  },

  data: () => ({
    loading: true,

    pengaduans: [],
    JPs: [],

    page: 0,
    totalRows: 0,

    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Pengaduan",
        disabled: true,
        href: "",
      },
      {
        text: "Beranda",
        disabled: false,
        href: "/pengaduan/beranda",
      },
    ],
  }),

  mounted() {
    this.getData();
    this.getJP();
  },

  methods: {
    pengaduanForm() {
      this.$router.push("/pengaduan/form").catch(() => {});
    },

    getData() {
      this.loading = true;
      this.http
        .get(
          process.env.VUE_APP_API_BASE + "pengaduan/public?page=" + this.page
        )
        .then((res) => {
          refreshView.commit("refreshData", false);
          this.pengaduans = res.data.data;
          this.page = res.data.page_number;
          this.totalRows = res.data.page_count;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getJP() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "jp")
        .then((res) => {
          this.JPs = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    tglFormat(tgl) {
      const date = new Date(tgl);
      const dateTimeFormat = new Intl.DateTimeFormat("id", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
      const formatted = dateTimeFormat.formatToParts(date);
      return (
        formatted[0].value + " " + formatted[2].value + " " + formatted[4].value
      );
    },

    detail(value) {
      this.$router.push("/pengaduan/detail/" + value).catch(() => {});
    },
  },
};
</script>
